import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "app",
  initialState: {
    isOnline: true,
    systemTimeDiff: 0,
  },
  reducers: {
    updateNetworkStatus: (state, { payload: { isOnline } }) => {
      return { ...state, isOnline };
    },
    setSystemTimeDiff: (state, { payload: { systemTimeDiff } }) => {
      return { ...state, systemTimeDiff };
    },
  },
});

export const { updateNetworkStatus, setSystemTimeDiff } = appSlice.actions;
export default appSlice.reducer;
