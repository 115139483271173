import { AlertName, AlertType } from "./alert-type";

import { enqueueItem } from "../common/queueSlice";
import { Dispatch } from "@reduxjs/toolkit";
import { actionType } from "../../src/config/constants";
import { getSystemTime } from "./common";

interface AlertOptions {
    alert: AlertName,
    dispatch: Dispatch
    description?: string | null;
    payload?: string | null;
}

export const alertHelper = () => {
    const getAlert = (alertOption: AlertOptions) => {
        const { alert, description, payload: base64Data } = alertOption;
        return {
            alert_type_id: AlertType[alert].id,
            description: description ? description : AlertType[alert].alertName,
            timestamp: getSystemTime().toISOString(),
            payload: base64Data ? { file: base64Data } : undefined
        };
    };

    const raiseAlert = async (alertOption: AlertOptions) => {
        const { dispatch } = alertOption;
        const alertData = getAlert(alertOption);
        dispatch(enqueueItem({ event: actionType.Alert, data: alertData}));
    };

    return { raiseAlert, getAlert };
};
