import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export interface SessionConnectResponse {
  session_id: string;
  streaming_token: string;
  slot_starts_at: string;
  slot_ends_at: string;
  video_provider: string;
  systemTimeDiff: number;
}

const proctorService = createApi({
  reducerPath: "proctorService",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_PROVIEW_API_URL,
    prepareHeaders: (headers: Headers) => {
      headers.set("app-id", process.env.REACT_APP_ID as string);
    },
  }),
  endpoints: (builder) => ({
    sessionConnect: builder.mutation({
      query: (body) => ({
        url: `v1/session/connect`,
        method: "POST",
        body,
      }),
      transformResponse: (response, meta) => {
        const serverTime = meta.response.headers.get('date');
        const systemTimeDiff = serverTime
          ? new Date(serverTime).getTime() - new Date().getTime()
          : 0;
        
        return {
          ...response as object,
          systemTimeDiff: systemTimeDiff,
        } as SessionConnectResponse;
      }
    }),
  }),
});

export const { useSessionConnectMutation } = proctorService;

export default proctorService;
