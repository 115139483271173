import { store } from "../store";

export const getSystemTime = (): Date => {
  const systemTimeDiff = store.getState().app.systemTimeDiff;
  const currentDate = new Date();

  if (!systemTimeDiff) return currentDate;

  const correctedDateTime = currentDate.getTime() + systemTimeDiff;
  return new Date(correctedDateTime);
};
